.radio-group {
    display: flex;
    flex-direction: column;
}

.radio-label {
    display: flex;
    align-items: center;
    padding: 0.4rem 0.5em;
    background-color: #fff;
    border: 1px solid var(--border_color_two);
    min-width: 6rem;
    font-size: .85rem;
    color: var(--text_color_one);
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.2s, border-color 0.2s;
}

/* .radio-label:hover {
    background-color: #e6e6e6;
  } */

.radio-input {
    position: absolute;
    opacity: 0;
    display: none;
}

.radio-input:checked+.radio-label {
    background-color: #fff;

}

.radio-input:focus+.radio-label {
    outline: 2px solid blue;
}

.radio-inner-circle {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid #B1C9FF;
    border-radius: 50%;
    margin-right: 0.5em;
    transition: border-color 0.2s;
    position: relative;
}

.radio-label:hover .radio-inner-circle {
    border-color: #B1C9FF;
}

.radio-input:checked+.radio-label .radio-inner-circle {
    border-color: #B1C9FF;
}

.radio-input:checked+.radio-label .radio-inner-circle::after {
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    border: 1px solid #B1C9FF;
    background-color: #5086FF;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* RESPONSIVENESS --------------------------------------------------> */

@media only screen and (max-width: 610px) {
    .radio-label {
        padding: 0.3rem 0.5em;
    }
}