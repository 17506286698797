.clientAccount-container {
    /* border: 1px solid red; */
    padding: 3rem 4rem;
    position: relative;
    padding-top: 0rem;
    background-image: url('../../../Assets/Images/Background.jpg');
    background-repeat: no-repeat;
    background-size: 100% 18rem;
}

.clientAccount-parent {
    /* border: 1px solid blue; */
    /* margin-top: 2rem; */
    padding-top: 2rem;
}

.content-container-profile {
    /* border: 1px solid purple; */
    /* height: 30rem; */
    border-radius: 15px;
    background-color: #fff;
    box-shadow: var(--boxShadow);
    display: flex;
    gap: 2rem;
}

.left-content-container-profile {
    /* border: 1px solid red; */
    width: 26%;
    border-radius: 15px;
    background: var(--gradient-seven);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2.5rem 1.5rem;
}

.breadCrumb-container-profile-section {
    /* border: 1px solid blue; */
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: sticky;
    top: 1rem;
}

.breadCrumb-profilesection {
    /* border: 1px solid red; */
    display: flex;
    padding: .7rem 1rem;
    gap: 1rem;
    align-items: center;
    cursor: pointer;
    transition: all .1s ease-in;
    border-radius: 5px;
}

.breadCrumb-profilesection.breadCrumb-profilesection-active {
    box-shadow: var(--boxShadowTwo);
    background-color: #fff;
}

.icon-profile-breadcrumb {
    /* border: 1px solid red; */
    width: 1.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.breadCrumb-profilesection>p {
    font-size: .9rem;
}

.right-content-container-profile {
    /* border: 1px solid red; */
    width: 74%;
    padding: 2.2rem 0rem;
    display: flex;
    padding-right: 2rem !important;
    flex-direction: column;
    gap: 1.5rem;
}

.heading_rightSide_findMycar-policyCard {
    display: flex;
    justify-content: space-between;
    /* border: 1px solid red; */
    flex-direction: row;
}

.btn-polsection {
    display: flex;
    /* width: 127px; */
    padding: 11.5px 20px;
    justify-content: center;
    align-items: center;
}