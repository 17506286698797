.switch-container {
    position: relative;
    display: inline-block;
    width: 70px;
    height: 35px;
}

.switch-container input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider-toggle {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 15px;
    border: 1px solid #B1C9FF;
    background: #FFF;
    transition: 0.4s;
}

.slider-toggle-circle {
    position: absolute;
    content: "";
    width: 24px;
    height: 24px;
    left: 4px;
    bottom: 4px;
    background-color: #DFE9FF;
    border-radius: 50%;
    transition: 0.2s;
    border: 1px solid #B1C9FF;
}

.slider-toggle-circle.slider-toggle-circle-active {
    transform: translateX(35px);
    background-color: #5086FF;
}

.toggle-status {
    position: absolute;
    top: 50%;
    left: 10px;
    right: 0;
    color: var(--primary);
    transform: translateY(-50%);
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.toggle-status-off {
    color: #000;
    position: absolute;
    top: 50%;
    left: 40px;
    right: 0;
    transform: translateY(-50%);
    color: var(--background-colorThree);
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}