.NoPoliciesFound-container {
  /* border: 1px solid red; */
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.NoPoliciesFound-tabs {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem;
  box-shadow: var(--boxShadowTwo);
  border-radius: 10px;

  /* border: 1px solid blue; */
}

.NoPoliciesFound-tabs-title {
  display: flex;
  gap: 1rem;
}

.NoPoliciesFound-tabs-btns {
  display: flex;
  gap: 1rem;
}

.NoPoliciesFound-tabs-title>p>span {
  color: var(--primary);
}

.NoPoliciesFound-tabs-title>p {
  color: var(--text_color_three);
}

.NoPoliciesFound-tabs-content>p {
  color: var(--text_color_one);
  font-size: .74rem;
}

.policyTypeTab {
  /* border: 1px solid red; */
  /* height: 2rem; */
  position: sticky;
  top: 0;
  background-color: #fff;
  border-radius: 10px;
  padding: .3rem 1rem;
  padding-bottom: .6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: var(--boxShadowTwo);
}

.accordian_button-policy-section {
  font-size: .75rem;
  padding: 0.2rem 0.6rem;
}

.PoliciesFound-container {
  width: 100%;
  /* border: 1px solid blue; */
  border-radius: 10px;
  padding: 1.2rem;
  gap: 1rem;
  box-shadow: var(--boxShadowFive);
}

.product-info-container {
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
}

.product-insurer-container>:first-child {
  color: #000;
  font-size: .9rem;
  font-weight: 500;
}

.product-insurer-container>:last-child {
  color: var(--text_color_two);
  /* font-size: .9rem; */
  font-weight: 500;
}

.product-icon-container {
  width: 79px;
  height: 46px;
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--gradient-eight);
  border-radius: 5px;
}

.product-info-left-container {
  display: flex;
  gap: 1rem;
}

.product-info-right-container {
  display: flex;
  /* justify-content: flex-start; */
  flex-direction: column;
  gap: .3rem;
  align-items: flex-end;
}

.product-info-right-container>p {
  color: #000;
}

.product-info-right-container>p>span {
  color: var(--text_color_one);
}

.seprator-policyCard {
  border: 1px solid var(--border_color_three);
}

.vehicleInfo-policyCard {
  /* align-items: flex-start; */
  display: flex;
  justify-content: space-between;
}

.textWithKey>span {
  color: var(--text_color_one);
}

.breakup-contianer-policyCard {
  /* height: 5rem; */
  border-radius: 5px;
  display: flex;
  padding: .5rem .5rem;
  gap: .5rem;
  background-color: var(--background-colorFive);
}

.textWithKey-bold {
  font-weight: 800;
  display: flex;
  gap: .5rem;
  width: 12rem;
  /* border: 1px solid rgb(95, 95, 3); */
  word-break: break-word;
}

.textWithKey-bold>span {
  font-weight: 500;
}

.breakup-contianer-policyCard>* {
  flex: 1 1;
  /* border: 1px solid green; */
}

.txt-with-tick-policycard {
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .5rem;
}

.text-color-one {
  color: var(--text_color_one);
}

.breakup-contianer-part-policyCard {
  border-right: 1.5px solid var(--border_color_two);
  padding-right: 1rem;
}

.breakup-contianer-part-policyCard:last-child {
  border-right: none;
  padding-right: 0rem;
}

.bottom-tabs-option-policyCard {
  background-color: var(--primary);
  /* height: 2.2rem; */
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  padding: .5rem 1rem;
}

.bottom-tabs-option-policyCard>* {
  flex: 1 1;
}

.tabs-option-policyCard {
  color: #fff;
  text-align: center;
  cursor: pointer;
  border-right: 1px solid #fff;
}

.tabs-option-policyCard:last-child {
  border-right: 0px;
}



/* <section className="NoPoliciesFound-container PoliciesFound-container ">
          <div className="product-info-container">
            <div className="product-info-left-container">
              <div className="product-icon-container">
                <img src={car} alt="" srcSet="" />
              </div>
              <div className="product-insurer-container">
                <p>987654321 - Private Car Policy Insurance</p>
                <p>HDFC Ergo</p>
              </div>
            </div>
            <div className="product-info-right-container">
              <p>
                <span>Start:</span> 24 Jan 2023
              </p>
              <p>
                <span>End:</span> 24 Jan 2023
              </p>
            </div>
          </div>

          <div className="seprator-policyCard"></div>

          <div className="vehicleInfo-policyCard ">
            <p className="textWithKey">
              <span>Name: </span>Vaibhav Dubey
            </p>
            <p className="textWithKey">
              <span>Car Number: </span> MH 01 CB 5421
            </p>
          </div>

          <div className="breakup-contianer-policyCard">
            <div className="breakup-contianer-part-policyCard">
              <div className="breakup-contianer-part-left-policyCard">
                <div className="txt-with-tick-policycard">
                  <p className="textWithKey textWithKey-bold">
                    <span>Invoice Cover</span> ₹11,222.41
                  </p>
                  <img src={tick} alt="" srcSet="" />
                </div>
                <div className="txt-with-tick-policycard">
                  <p className="textWithKey textWithKey-bold">
                    <span>Invoice Cover</span> ₹11,222.41
                  </p>
                  <img src={tick} alt="" srcSet="" />
                </div>
                <div className="txt-with-tick-policycard">
                  <p className="textWithKey textWithKey-bold">
                    <span>Invoice Cover</span> ₹11,222.41
                  </p>
                  <img src={tick} alt="" srcSet="" />
                </div>
              </div>
            </div>

            <div className="breakup-contianer-part-policyCard">
              <div className="breakup-contianer-part-left-policyCard">
                <div className="txt-with-tick-policycard">
                  <p className="text-color-one">Engine Protector</p>
                  <img src={tick} alt="" srcSet="" />
                </div>
                <div className="txt-with-tick-policycard">
                  <p className="text-color-one">Consumable</p>
                  <img src={tick} alt="" srcSet="" />
                </div>
                <div className="txt-with-tick-policycard">
                  <p className="text-color-one">Engine Protector</p>
                  <img src={tick} alt="" srcSet="" />
                </div>
              </div>
            </div>

            <div className="breakup-contianer-part-policyCard">
              <div className="breakup-contianer-part-left-policyCard">
                <div className="txt-with-tick-policycard">
                  <p className="text-color-one">Engine Protector</p>
                  <img src={tick} alt="" srcSet="" />
                </div>
                <div className="txt-with-tick-policycard">
                  <p className="text-color-one">Consumable</p>
                  <img src={tick} alt="" srcSet="" />
                </div>
                <div className="txt-with-tick-policycard">
                  <p className="text-color-one">Engine Protector</p>
                  <img src={tick} alt="" srcSet="" />
                </div>
              </div>
            </div>
          </div>

          <div className="bottom-tabs-option-policyCard">
            <div className="tabs-option-policyCard">
              <p>Download Policy</p>
            </div>
            <div className="tabs-option-policyCard">
              <p>Edit Policy</p>
            </div>
            <div className="tabs-option-policyCard">
              <p>Initiate Claim</p>
            </div>
            <div className="tabs-option-policyCard">
              <p>Track Claim</p>
            </div>
            <div className="tabs-option-policyCard">
              <p>Garages</p>
            </div>
          </div>
        </section> */