.collapsible_container {
    /* border: 1px solid red; */
    padding: 1rem 0rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    position: relative;
}

.absolute-hyperlink {
    position: absolute;
    right: 2px;
    border: none;
    outline: none;
    background-color: #fff;
    top: -1.2rem;
}

.collapsible_head_content {
    /* border: 1px solid blue; */
    border-radius: 10px;
    padding: .5rem 1rem;
    display: flex;
    cursor: pointer;
    flex-direction: column;
    box-shadow: var(--boxShadowThree);
    background-color: #fff;
}

.collapsible_head {
    padding: .7rem 1rem;
    display: flex;
    justify-content: space-between;
}

.collapsible_head_withBorder {
    border-bottom: 1px solid var(--border_color_three);
    gap: 10px;

}

.arrow-collapsible-down {
    width: 1rem;
    transition: transform .2s ease;
    /* transform: rotateZ(-90deg); */
}

.arrow-collapsible-down.arrow-collapsible-up {
    transform: rotateZ(-180deg);
}

.collapsible_head_title {
    font-size: .9rem;
    font-weight: 500;
    color: var(--text_color_three);
}

.collapsible_content {
    /* border: 1px solid red; */
    padding: 0rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    height: 0;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: all 1s cubic-bezier(0, 1, 0, 1);
}

.collapsible_content.show_collapsible_content {
    padding: 2rem 1rem;
    height: auto;
}

.collapsible_content>* {
    flex: 1 1;
    /* border: 1px solid red; */
}

.collapsible_content_group {
    display: flex;
    justify-content: space-between;
}

.collapsible_content_group>* {
    flex: 1 1;
}

.collapsibleHead {
    font-size: .85rem;
    color: var(--text_color_one);
}

.collapsibleDesc {
    font-size: .85rem;
    color: var(--text_color_three);
}

.collapsibleTab {
    display: flex;
    flex-direction: column;
    gap: 7px;
}

.btnContainer_pform_collapsible {
    /* background-color: var(--background-colorSeven); */
    padding: 1rem 0rem;
}

.main-collapsible {
    position: relative;
}

/* .btnContainer_pform_collapsible-fixed-mobile {
    border: 1px solid red;
    width: 100%;
    position: fixed;
    height: 2rem;
    background-color: red;
} */

@media only screen and (max-width: 610px) {

    .adaptive-breakup-proposal {
        display: flex;
        flex-direction: column;
        gap: 10px;
        /* border: 1px solid red; */
        overflow: hidden;
        height: 0;
        transition: height .5s cubic-bezier(0, 1, 0, 1);
    }

    .adaptive-breakup-proposal.active-adaptive-breakup-proposal {
        height: 100%;
        overflow: auto;
    }

    .collapsible_head {
        padding: 0.7rem 0rem;
    }

    .collapsible_content.show_collapsible_content {
        padding: 1rem 0rem;
    }

    .absolute-hyperlink {
        font-size: .8rem !important;
    }

    .btnContainer_pform_collapsible {
        /* display: none; */
        background-color: var(--background-colorSeven);
        padding: 1rem 1rem;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .collapsible_container {
        gap: 1rem;
    }

    .collapsible_content_group {
        flex-direction: column;
        gap: 1rem;
    }

    .collapsibleTab {
        flex-direction: row;
    }

    .collapsible_content {
        gap: 1rem;
    }
}