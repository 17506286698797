.request-msg-container {
    border: 1px solid var(--background-colorThree);
    padding: 1rem;
    border-radius: 5px;
    background: var(--background-color);
    margin-top: 1rem;
    position: relative;
    padding-top: 1.5rem;
}

.request-msg-container>p {
    font-size: .7rem;
}

.req-status-btn {
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: var(--border_color_three); */
    padding: 3px 10px;
    font-size: .7rem;
    border-radius: 5px;
    position: absolute;
    border: none;
    outline: none;
    top: -14px;
}

.green-background {
    background-color: var(--background-color-green) !important ;
}

.light-blue-background {
    background-color: var(--border_color_three) !important ;
}

.peach-background {
    background-color: var(--background-color-peach) !important ;
}

.orange-background {
    background-color: var(--background-color-orange) !important ;
}

.yellow-background {
    background-color: var(--background-color-yellow) !important ;
}

.dark-green-background {
    background-color: var(--greenText) !important ;
}

.red-background {
    background-color: var(--redTxt) !important ;
}