* {
    margin: 0;
    padding: 0;
    /* overflow: hidden; */
    box-sizing: border-box;
    font-family: 'Kanit', sans-serif;
    font-family: 'Poppins', sans-serif;
    /* outline: 1px solid red; */
    /* text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; */
}

body {
    font-size: .8rem;
    font-weight: 400;
}

:root {
    --gradient-one: linear-gradient(134deg, #DFE9FF 0%, #FEE6E7 100%);
    --gradient-two: radial-gradient(55.52% 55.52% at 54.83% 100.00%, #FEE6E7 0%, rgba(255, 255, 255, 0.00) 100%);
    --gradient-three: linear-gradient(133deg, #DFE9FF 0%, rgba(255, 255, 255, 0.00) 100%);
    --gradient-four: linear-gradient(136deg, rgba(177, 201, 255, 0.80) 0%, rgba(254, 230, 231, 0.80) 100%);
    --gradient-five: linear-gradient(137deg, #E3E4FF 0%, #FFF 100%);
    --gradient-six: linear-gradient(118deg, rgba(177, 201, 255, 0.80) -3.56%, rgba(254, 230, 231, 0.80) 103.92%);
    --gradient-seven: linear-gradient(163deg, #E3E4FF -12.1%, #FFF 121.84%);
    --gradient-eight: linear-gradient(126deg, #E3E4FF -6.78%, #FEE6E7 102.48%);
    --gradient-nine: linear-gradient(103deg, #E3E4FF -1.28%, #FEE6E7 120.79%);
    --gradient-ten: linear-gradient(143deg, #E3E4FF -11.58%, #FEE6E7 109.55%);
    --gradient-eleven: linear-gradient(143deg, #E3E4FF -11.58%, #FEE6E7 109.55%);
    --gradient-twelve: linear-gradient(100deg, #DFE9FF -0.55%, #FEE6E7 106.25%);
    --gradient-thirteen: 0px 4px 25px 0px rgba(80, 134, 255, 0.25);
    --gradient-fourteen: linear-gradient(169deg, #DFE9FF -1.71%, rgba(255, 255, 255, 0.00) 107.73%);
    --primary: #5086FF;
    --secondry: #F3F7FF;
    --background-color: #F1F5FF;
    --background-colorTwo: #E2EBFF;
    --background-colorThree: #B1C9FF;
    --background-colorFour: #D9D9D9;
    --background-colorFive: #F9FBFF;
    --background-colorSix: #EDF3FF;
    --background-colorSeven: #EEEFFF;
    --background-colorEight: #E1EAFF;
    --background-colorNine: #F2F6FF;
    --background-color-green: #7EECC4;
    --background-color-peach: #FEE6E7;
    --background-color-orange: #ECC67E;
    --background-color-yellow: #DDEC7E;
    --maxWidth: 1300px;
    --text_color_one: #60646A;
    --text_color_two: #B2B2B2;
    --text_color_three: #1A1B1D;
    --text_color_four: #4D4D4D;
    --text_color_five: #5E5E5E;
    --greenText: #23D32A;
    --greenTwo: #17CB34;
    --redTxt: #FF464D;
    --redTxt_two: #ED1C24;
    --border_color: #B2B2B2;
    --border_color_two: #B1C9FF;
    --border_color_three: #DFE9FF;
    --border_color_four: #C5D7FF;
    --border_color_five: #DFDFFD;
    --border_color_six: #E4E4E4;
    --boxShadow: 0px 0px 30px 0px rgba(80, 134, 255, 0.20);
    --boxShadowTwo: 0px 0px 10px 0px rgba(80, 134, 255, 0.20);
    --boxShadowThree: 0px 0px 24px 0px rgba(80, 134, 255, 0.20);
    --boxShadowFour: 0px 5px 15px -7px rgba(0, 0, 0, 0.16);
    --boxShadowFive: 0px 0px 20px 0px rgba(80, 134, 255, 0.20);
    /* --boxShadowSix: drop-shadow(0px 4px 25px rgba(80, 134, 255, 0.25)); */
}

.blue-txt {
    color: var(--primary) !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    appearance: textfield;
    -moz-appearance: textfield;
}

.maxWidth {
    max-width: var(--maxWidth);
    margin: auto;
}

.button {
    background-color: var(--primary);
    color: #fff;
    border-radius: 5px;
    display: flex;
    border: none;
    /* padding: 1rem 0rem; */
    font-size: .8rem;
    outline: none;
    transition: all .1s ease;
    cursor: pointer;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.button:hover {
    background-color: #2C5ECD;
}

.secondry_btn {
    border: 1px solid var(--primary) !important;
    cursor: pointer;
    color: var(--primary);
    border-radius: 5px;
    background-color: #F3F7FF;
}

.secondry_btn:hover {
    background-color: #DFE9FF;
}

.small_text {
    font-size: .85rem;
    font-weight: 400;
    cursor: pointer;
    color: var(--text_color_one);
}

.inputs-form {
    border: 1px solid var(--border_color_two);
    border-radius: 5px;
    height: 100%;
    width: 100%;
    padding: 1rem;
    outline: none;
}

.inputs-form :focus {
    border: none;
    outline: none;
}

.inputs-form::placeholder {
    color: var(--text_color_two);
}

.input-err {
    border: 1px solid var(--redTxt) !important;
    /* color: var(--redTxt) !important; */
}

.err-msg {
    color: var(--redTxt);
    margin-top: .3rem;
    font-size: .7rem;
}

.underline_text {
    cursor: pointer;
    text-decoration: underline;
}

.edit_btn {
    border: 1px solid var(--primary);
    border-radius: 5px;
    background: #FFF;
    width: 79px;
    height: 28px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    gap: .3rem;
    color: var(--primary);
}

/* SCROLLBAR */

::-webkit-scrollbar {
    width: .4em;
    height: .4em;
}

::-webkit-scrollbar-track {
    position: relative;
    right: 5px;
    border-radius: 20px;
}

::-webkit-scrollbar-thumb {
    background: var(--border_color_three);
    border-radius: 20px;
    outline: none;
}

a {
    text-decoration: none;
}

@media only screen and (max-width: 610px) {

    .small_text {
        font-size: .9rem !important;
    }

    body {
        font-size: .9rem;
    }

    .button {
        font-size: .9rem;
    }
}