@media only screen and (max-width: 610px) {
    .cards_container {
        padding: 2rem 1rem;
    }

    .card_container_child {
        justify-content: space-around;
        gap: 1rem;
    }

    .card_div_container {
        gap: 1rem;
    }

    .card_landing {
        width: 45%;
        margin-bottom: 10px;
        min-height: 14rem;
        min-width: 0;
        padding: 1rem;
        border-radius: 20px;
    }
}

@media only screen and (min-width: 610px) and (max-width: 1111px) {
    .card_container_child {
        justify-content: flex-start;
    }
}