.accordian_container_main {
    /* border: 1px solid blue; */
    padding: 3rem 4rem;
    padding-top: 2rem;
}

.accordian_parent_container {
    /* border: 1px solid blue; */
    padding: 2rem 2rem;
    border-radius: 20px;
    background-color: #fff;
    box-shadow: var(--boxShadow);
}

.accordian_container_title {
    margin-bottom: 2rem;
}

.accordian_item {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--border_color);
}

.accordian_container_nav {
    /* border: 1px solid violet; */
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

.accordian_title {
    /* border: 1px solid blue; */
    cursor: pointer;
    display: flex;
    padding: 1rem 0rem;
    padding-bottom: .5rem !important;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
}

.accordian_title>span {
    color: var(--primary);
    font-size: 1.5rem;
}

.accordian_content {
    color: var(--text_color_one);
    max-height: 0;
    font-size: .86rem;
    cursor: pointer;
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0, 1, 0, 1);
}

.accordian_content.accordian_content_show {
    height: auto;
    width: 90%;
    max-height: 999px;
    transition: all 0.5s cubic-bezier(0, 1, 0, 1);
}

.accordian_title_text {
    font-size: 1.1rem;
}

.accordian_button {
    padding: .5rem 1rem;
    color: var(--text_color_two);
    background-color: #fff;
    border: 2px solid transparent;
    cursor: pointer;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: .9rem;
    transition: all .1s ease-in-out;
}

.active_accordian_btn {
    color: var(--primary);
    font-weight: 500;
    border-bottom: 2px solid var(--primary);
}

@media only screen and (max-width: 610px) {
    .accordian_container_main {
        padding: 2rem 1rem;
    }

    .accordian_parent_container {
        padding: 1rem 1rem;
    }

    .accordian_item:last-child {
        border-bottom: 0;
    }

    .accordian_container_nav {
        justify-content: space-between;
    }

    .accordian_button {
        color: var(--text_color_one);
        font-size: 1rem;
    }

    .partner_logo_container_button {
        width: 85%;
    }
}

@media only screen and (max-width: 387px) {
    .accordian_container_nav {
        flex-wrap: wrap;
        gap: 0rem;
        /* border: 1px solid red; */
    }
}