.feature_container {
    padding: 3rem 4rem;
    padding-bottom: 0rem;
}

.feature_container_parent {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    padding-bottom: 0rem;
}

.title {
    font-size: 1.5rem;
    font-weight: 400;
}

.title>span {
    font-weight: bold;
}

.featureParts_container {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
}

.featureParts_container_childOne {
    width: 55%;
}

.featureParts_container_childTwo {
    position: relative;
    display: flex;
    justify-content: center !important;
    align-items: center;
    width: 45%;
}

.featureParts_container_childTwo>img {
    width: 80%;
    position: relative;
    bottom: 4rem;
}

.featureParts_container_child {
    display: flex;
    justify-content: space-between;
}

.featureParts_container_child_parts {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.bubble_landing {
    height: 4.5rem;
    width: 4.5rem;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    align-items: center;
    background: var(--background-color);
}

.bubble_landing>p {
    font-weight: 550;
    color: #000;
    font-size: 1.1rem;
}

.bubble_landing>p>span {
    color: var(--primary);
}

.bubble_landing_content_titleBold {
    font-size: 1rem !important;
    font-weight: bold;
    color: #000 !important;
}

.bubble_landing_content_title {
    font-size: .85rem;
    color: var(--text_color_one);
}

.buble_container_landing {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.card_div_container {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    /* border: 1px solid red; */
}

.icon_container_landing {
    /* border: 1px solid red; */
    height: 4rem;
    /* display: none; */
    margin-bottom: 0rem;
}

.icon_container_landing>img {
    width: 2.5rem;
}

.cards_container {
    padding: 3rem 4rem;
    background-color: var(--background-color);
}

.cardHead_title {
    margin-bottom: 2rem;
}

.card_container_child {
    display: flex;
    gap: 3rem;
    flex-wrap: wrap;
    justify-content: space-between;
    /* border: 1px solid blue; */
}

.card_landing {
    border-radius: 25px;
    background: #fff;
    padding: 2rem;
    min-height: 13rem;
    width: 21%;
    min-width: 210px;
    /* width: 15rem; */
    cursor: pointer;
    display: flex;
    box-shadow: var(--boxShadow);
    flex-direction: column;
    transition: all 1s ease-in-out;
}


.cardLanding_content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    /* border: 1px solid red; */
    /* height: 10rem; */
}

.cardLanding_content>p:first-child {
    font-size: 1.1rem;
    font-weight: 550;
}

.cardLanding_content>p:last-child {
    font-size: .85rem;
    color: var(--text_color_one);
    /* width: 11rem; */
    /* border: 1px solid red; */
    word-break: break-word;
}

.TestimonialContainer_landing {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
}

#mobile_lady_div {
    display: none;
}

.mobile_feature_head {
    /* border: 1px solid red; */
    display: flex;
    justify-content: space-between;
}

.mobile_lady_div {
    width: 100%;
    position: relative;
}

.mobile_lady_div>img {
    width: 13rem;
    position: absolute;
    right: -1rem;
}

/* MEDIA - QUERIES */

@media only screen and (max-width: 360px) {
    .mobile_lady_div>img {
        width: 11rem;
    }

    .logo_container_partner {
        width: 7rem !important;
    }
}


@media only screen and (max-width: 603px) {

    .cardLanding_content{
        gap: .5rem;
    }    

    .icon_container_landing{
        height: 3.8rem;
    }
    
    .icon_container_landing{
        gap: .5rem;
    }

    .feature_container_parent {
        padding-bottom: 0rem;
    }

    .featureParts_container_childTwo {
        display: none;
    }

    .featureParts_container_childOne {
        width: 100%;
        justify-content: flex-start;
    }

    #mobile_lady_div {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .feature_container {
        padding: 3rem 1rem;
    }

    .mobile_feature_head {
        align-items: center;
        padding: 2rem 0rem;
        padding-bottom: 3rem;
    }

    .featureParts_container_child_parts {
        gap: 1.5rem;
    }

}


@media only screen and (min-width: 1366px) {
    .card_landing{
        width: 22%;
    }
  }