#cover-spin {
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 9999;
    backdrop-filter: blur(2px) !important;
    /* touch-action: none; */
    /* display:none; */
}

.gifLoader {
    display: block;
    width: 450px;
    height: 450px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media only screen and (max-width: 610px) {
    .gifLoader {
        width: 18rem;
        height: 18rem;
    }
}