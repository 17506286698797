.testimonial_container {
    /* border: 1px solid red; */
    padding: 0rem 4rem;
    background: var(--gradient-two);
    margin-bottom: 2rem;
    padding-top: 2rem;
}

.testimonial_parent_container {
    /* border: 1px solid blue; */
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.testimonial_card_container {
    /* border: 1px solid orange; */
    padding: 1rem;
    padding-left: 0rem;
}

.slider_testimonial_container>* {
    flex: 1 1;
}

.testimonial_card_parent {

    /* border: 1px solid red; */
    padding: 2rem 3rem;
    padding-left: 2rem;
}

.testimonial_card_child {
    /* border: 1px solid blue; */
    cursor: grab;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 3rem 2rem;
    padding-bottom: 1rem !important;
    border-radius: 24px;
    box-shadow: var(--boxShadow);
}

.quotes_testimonial {
    position: absolute;
    top: -1.5rem;
    /* opacity: .5; */
    width: 3rem;
}

.testimonial_content {
    color: var(--text_color_three);
    font-size: .95rem;
}

.testimonial_profile_container {
    /* border: 1px solid red; */
    padding: 1.5rem 0rem;
    display: flex;
    gap: 2rem;
    align-items: center;
}

.img_testimonial {
    width: 5rem;
    height: 5rem;
    background-color: rgb(255, 255, 255);
    border-radius: 50%;
    border: 3px solid #fff;
    filter: drop-shadow(-4px 14px 54px #E3E4FF);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: #F7E7FF;
}

.img_testimonial>img {
    width: 63%;
    position: relative;
    -webkit-filter: drop-shadow(-4px 14px 54px #E3E4FF);
    filter: drop-shadow(-4px 14px 54px #E3E4FF);
    /* box-shadow: -5px 1px 11px 0px rgba(0, 0, 0, 0.18); */
    top: 6px;
    /* filter: drop-shadow(-4px 14px 54px #E3E4FF); */
}

.name_testimonial {
    color: var(--primary);
    font-size: 1rem;
    font-weight: 500;
}

.controls_testimonial {
    /* border: 1px solid red; */
    display: flex;
    justify-content: flex-end;
    gap: 2rem;
    padding: 0rem 3rem 1rem 3rem;
}

.controller_carousel {
    width: 3rem;
    height: 3rem;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: var(--boxShadow);
    border-radius: 10px;
    cursor: pointer;
    outline: none;
    background-color: #fff;
}

.controller_carousel>img {
    width: 1.5rem;

}

.controller_carousel_right>img {
    transform: scaleX(-1);
}

.disabled {
    pointer-events: none;
    opacity: 0.5;
    /* Adjust the opacity to your preference */
    cursor: not-allowed;
}

/* RESPONSIVE */

@media only screen and (max-width: 593px) {
    .testimonial_container {
        padding: 1rem 1rem;
        margin-bottom: 0rem;
    }

    .testimonial_card_parent {
        padding: 2rem 0rem;
    }

    .testimonial_card_container {
        padding: 0;
    }
}

@media only screen and (max-width: 610px) {

    .controller_carousel {
        box-shadow: none;
    }

    .controls_testimonial {
        gap: 1rem;
        padding: 0rem 0rem 1rem 3rem;
    }

    .testimonial_card_child {
        padding: 3rem 1rem;
        box-shadow: none;
        border: 1px solid #b2caff;
    }

    .img_testimonial {
        filter: none;
    }

    .testimonial_profile_container {
        gap: 1.5rem;
    }
}