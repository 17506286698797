.loginContainer {
    /* border: 1px solid red; */
    padding: 5% 4rem;
    background: var(--gradient-four);
}

.loginParentContainer {
    /* border: 1px solid blue; */
    padding: 1.5rem;
    border-radius: 15px;
    background-color: #fff;
    /* width: fit-content; */
    display: flex;
    gap: 2rem;
    width: 53rem;
    box-shadow: var(--boxShadow);
}

.loginParentContainer>* {
    flex: 1 1;
}

.login-child-content {
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    /* gap: 1.5rem; */
    padding: 1.5rem;
    background: var(--gradient-four);
}

.login-child-head {
    font-size: 1.2rem;
    font-weight: 500;
}

.login-child-text {
    font-size: .8rem;
    /* border: 1px solid red; */
    color: var(--text_color_one);
    /* width: 14rem; */
}

.login-child-text-two {
    width: auto;
    /* display: flex; */
}

.login-child-text-margin {
    margin-top: .5rem;
    margin-bottom: 1.5rem;
}

.feature_container-login {
    display: flex;
    /* border: 1px solid blue; */
    border-radius: 15px;
    background-color: #fff;
    justify-content: space-between;
    padding: 1.2rem 2rem;
    gap: 1rem;
}

.content-login-left {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

}

.content-login-left>p {
    font-size: .7rem;
    text-align: center;
    color: var(--text_color_one);
}

.login-child-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 1rem;
    justify-content: center;
}

.login-btn {
    height: 52px;
}

.form-login-head {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: .5rem;
}

.footer_hyper_link-login {
    font-size: .75rem;
}

.otpInput-container {
    /* border: 1px solid red; */
    display: flex;
    gap: 2rem;
}

.otpInput-container>div {
    /* background-color: red; */
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.otpInput-container>div>input {
    height: 52px;
    width: 42px !important;
    border: 1px solid var(--border_color_two);
    border-radius: 5px;
}

.otpInput-container>div>input:focus {
    border: 1px solid var(--primary) !important;
    outline: 1px solid var(--primary) !important;
}

.footer-login-text {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
}

@media only screen and (max-width: 610px) {
    .loginContainer {
        padding: 14dvh 1rem;
        /* border: 1px solid red; */
    }

    .loginParentContainer {
        flex-direction: column-reverse;
        width: 100%;
    }

    .login-child-form {
        padding: 0rem;
        gap: 1rem;
    }

    .form-login-head {
        margin-bottom: 0rem;
    }

    .login-child-content {
        padding: 0rem;
    }

    .login-child-head-hide {
        display: none;
    }

    .login-child-text-margin {
        display: none;
    }

    .feature_container-login {
        margin: 0rem -1.5rem -1.5rem -1.5rem;
        /* border: 1px solid red; */
        background-color: var(--background-color);
    }

    .hideInMob-login {
        display: none;
    }

    .footer-login-text {
        flex-direction: column;
        align-items: center;
    }

    .login-child-text-three {
        display: flex;
        gap: 1rem;
    }
}