.viewPlans_content_container {
    /* border: 1px solid red; */
    background: var(--gradient-one);
    padding: 2rem 4rem;
    padding-bottom: 6rem;
}

.viewPlans_content_parent {
    /* border: 1px solid blue; */
    display: flex;
    gap: 2rem;
    justify-content: space-between;
}

.plansContainer_viewPlan {
    /* border: 1px solid blue; */
    padding: 2rem 4rem;
    padding-top: 0rem;
    padding-bottom: 0rem;
    position: relative;
}

.plansContainer_viewPlan_parent {
    /* border: 1px solid red; */
    position: relative;
    bottom: 4rem;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    box-shadow: var(--boxShadow);
}

.topContent_vp {
    border-radius: 15px;
    width: 100%;
    /* border: 1px solid red; */
    padding: 2rem 2rem;
    box-shadow: var(--boxShadow);
    /* margin-bottom: 2rem; */
    background-color: #fff;
    display: flex;
    justify-content: space-between;
}

.content_container_vp {
    /* border: 1px solid blue; */
    display: flex;
    flex-direction: column;
    gap: .5rem;
    position: relative;
}

.topContent_vp_title {
    font-size: .8rem;
    font-weight: 600;
}

.topContent_vp_desc {
    font-size: .8rem;
    /* font-weight: 500; */
    color: var(--primary);
}

.topContent_vp_desc-border {
    border: 1px solid var(--border_color_two);
    width: 9.4rem;
    padding: 5px 5px;
    display: flex;
    border-radius: 5px;
    align-items: center;
}

.topContent_vp_desc-border-two {
    border: 1px solid var(--border_color_two);
    width: 9rem;
    padding: 5px 5px;
    display: flex;
    border-radius: 5px;
    align-items: center;
}

.viewPlan_paper_container {
    /* border: 1px solid blue; */
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.viewPlan_paper_head {
    gap: 2rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.viewPlan_paper_head_txt {
    font-weight: 600;
    font-size: .9rem;
    color: var(--text_color_three);
}

.viewPlan_paper_child_container {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
}

.viewPlan_paper_child_container-new {
    display: flex;
    /* border: 1px solid blue; */
    justify-content: space-between;
    /* width: 100%; */
}

.viewPlan_paper_child-new {
    flex-direction: row !important;
    gap: 11rem !important;
}

.viewPlans_content_child {
    border-right: 1px solid #fff;
    padding-right: 1rem;
    width: 33%;
    /* border: 1px solid blue;  */
}

.viewPlan_paper_child_container-new>* {
    flex: 1 1;
}

.viewPlan_paper_child_padding {
    padding-left: 2rem;
    /* border: 1px solid blue; */
    /* width: 45%; */
}

.viewPlans_content_childOne {
    width: 48% !important;
    padding-right: 0;
}

.viewPlans_content_child:last-child {
    border-right: none;
    padding-right: 0;
    border-left: 1px solid #fff;
    padding-left: 2rem;
}

.viewPlans_content_child:nth-child(2) {
    border-right: none;
}

.viewPlan_paper_child_container>* {
    flex: 1 1;
}

.viewPlan_paper_child {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.viewPlan_paper_child_head {
    font-size: .75rem;
    color: var(--text_color_three);
    font-weight: 600;
}

.viewPlan_paper_child_title {
    font-size: .75rem;
    width: 80%;
    color: var(--text_color_one);
}

.viewPlan_paper_child_head_title {
    display: flex;
    flex-direction: column;
    gap: .3rem;
}

.title_topCont_vp {
    display: flex;
    gap: 10px;
}

.bottomContent_container_vp {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    /* gap: 1rem; */
    /* border: 1px solid red; */
    padding-top: 0rem;
}

.bottomContent_header_vp_one {
    display: flex;
    border-bottom: 1px solid var(--border_color_three);
    padding-bottom: 1rem;
    justify-content: space-between;
}

.title_vp_flex {
    flex-direction: column;
    align-items: flex-end;
    gap: 0.6rem;
}

.title_vp_flex-mobile {
    padding: 1rem;
    align-items: flex-start;
    display: none;
}

.smallFont {
    font-size: .75rem;
}

.bottomContent_header_vp_two {
    /* padding: 1rem 0rem; */
    display: flex;
    gap: 1rem;
    /* padding-bottom: 0rem; */
    /* position: relative; */
    justify-content: space-between;
}

.modified-err-msg {
    padding-left: 2.5rem;
    position: relative;
    bottom: 0.5rem;
}

.section_bottomContent_vp {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.section_bottomContent_vp-fullWidth {
    width: 75%;
}

.section_bottomContent_vpOne {
    width: 25%;
    /* border: 1px solid red; */
}

.section_bottomContent_vpTwo {
    width: 75%;
    /* border: 1px solid blue; */
}

.dropDownLabel {
    font-size: .85rem;
}

.addonContainer_vp {
    border: 1px solid var(--border_color_two);
    border-radius: 5px;
    padding: 1rem;
    position: sticky;
    display: flex;
    top: 60px;
    flex-direction: column;
    gap: 1rem;
}

.addonContainer_vp_title {
    color: var(--text_color_one);
    font-size: .9rem;
    font-weight: 500;
}

.idvInput_VP {
    border: 1px solid var(--primary);
    color: var(--primary);
    font-weight: 400;
    outline: none;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    width: 5rem;
    padding: 0px 3px;
    transition: all .1s ease-in;
    background: transparent;
}

.idvInput_VP-mobile {
    outline: none;
    border-bottom: 1.5px solid var(--primary);
}

/* .idvInput_VP:focus {
    outline: none;
    border-bottom: 1.5px solid var(--primary);
} */

.datepicker-viewplans {
    /* border: 1px solid red; */
    width: 120px;
}

.title_rightSide_modal-vp {
    font-weight: 700;
}

.editDetail_container {
    width: 50rem;
    /* border: 1px solid red; */
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.editDetail_container_caseTwo {
    height: 25rem;
}

.editDetail_header {
    display: flex;
    justify-content: space-between;
}

.editDetail_header>p {
    font-size: 1rem;
    font-weight: 500;
}

.editDetail_header>img {
    cursor: pointer;
}

.input_group_editForm {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    position: relative;
}

.input_group_editForm>* {
    flex: 1 1;
}

.ncbMsg-editForm {
    color: var(--greenText);
    position: absolute;
    font-size: .8rem;
    bottom: 0;
    left: 0;
}

.input_group_editFormTwo {
    overflow: hidden;
    padding-bottom: 2rem;
}

.ncb-btn-editform {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}

.ncbBtn-editForm {
    width: 38px;
    height: 30px;
    outline: none;
    border: 1px solid var(--border_color_two);
    border-radius: 5px;
    background-color: #fff;
    font-size: .75rem;
    color: var(--text_color_one);
    cursor: pointer;
}

.activeNcbBtn {
    color: var(--primary);
    background-color: var(--border_color_three);
}

.tooltip-container {
    position: relative;
    display: inline-block;
    /* z-index: 9; */
}

.tooltip-trigger {
    display: inline-block;
    cursor: pointer;
}

.tooltip {
    position: absolute;
    top: -44%;
    left: 110%;
    transform: translateY(-50%);
    background-color: var(--border_color_three);
    color: var(--text_color_one);
    border-radius: 5px;
    width: 282px;
    min-height: 90px;
    padding: 20px;
    z-index: 10;
    font-size: 0.7rem;
    border-radius: 5px;
    transition: opacity 1.3s ease-in-out;
    /* Add fade-in transition */
    border: 1px solid var(--border_color_two);
}

.tooltip-arrow {
    position: absolute;
    top: 12%;
    right: 100%;
    /* Position the arrow to the right of the tooltip */
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7px;
    border-color: transparent var(--border_color_three) transparent transparent;
    /* Match the tooltip background color */
}


.addonContainer__ {
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 1rem;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.disable-container {
    Pointer-events: none;
    /* opacity: 0.5; */
}

.custom-modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    /* Semi-transparent overlay */
    display: flex;
    /* z-index: 99999999999999; */
    justify-content: center;
    align-items: center;
}

.accesoriesContainer-fields {
    border: 1px solid var(--border_color_two);
    background-color: var(--background-color);
    padding: 15px;
    border-radius: 5px;
    color: var(--text_color_one);
    /* position: absolute; */
    bottom: 11rem;
    width: 30rem;
    left: 8rem;
    pointer-events: all !important;
    z-index: 3;
}

.sumInsuredContainer-fields {
    bottom: 3rem;
    width: 20rem;
    left: 10rem;
    padding: 20px;
}

.sum-insured-btns {
    /* border: 1px solid blue; */
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 0.6rem;
}

.btn-sumInsurd {
    width: 83px;
    height: 30px;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
    border: 1px solid var(--background-colorThree);
    background-color: #fff;
    color: var(--text_color_one);
}

.btn-sumInsurd-apply {
    width: 116px;
    height: 40px;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
    border: none;
}

.btn-sumInsurd-active {
    background-color: var(--border_color_three);
    border: 1px solid var(--primary);
    color: var(--primary);
}

.inputGroup-accesories {
    /* border: 1px solid red; */
    display: flex;
    gap: 2rem;
    align-items: flex-start;
}

.fields-accesories {
    display: flex;
    flex-direction: column;
    gap: .4rem;
    margin-bottom: .5rem;
}

.inputGroup-accesories>* {
    flex: 1 1;
    /* border: 1px solid blue; */
}

.fields-accesories-title {
    font-size: .75rem;
    color: #000;
}

.inputGroup-accesories>p {
    font-size: .75rem;
}

.fields-accesories-btn-container {
    /* border: 1px solid red; */
    margin-top: 1rem;
    display: flex;
    justify-content: flex-start;
    gap: 1.5rem;
}

.btn-accesories {
    width: 169px;
    height: 52px;
}

.passengerDp-container {
    /* border: 1px solid red; */
    padding-top: 1rem !important;
    width: 14.3rem;
    padding: .5rem;
    display: flex;
}

.editDetail_container-claim {
    /* border: 1px solid red; */
    height: 27rem;
}

.editDetail_container-claim-height {
    height: fit-content;
}

.viewPlans_content_child-new {
    width: 100%;
}

#noBorderVp {
    border-right: 0px;
}

#noBorderLeft {
    border-left: 0px;
}

#flexStart {
    justify-content: flex-start;
}

.err-msg-claim {
    color: var(--redTxt);
    /* margin-top: 0.3rem; */
    /* font-size: .7rem; */
}

.dropdown-viewPlans-container-mobile {
    display: none;
}

.mobile-top-content-container {
    /* border: 1px solid red; */
    /* height: 10rem; */
    display: none;
    border-radius: 0px 0px 20px 20px;
    padding: 1.5rem 1rem;
    /* margin-bottom: 1rem; */
    /* background-color: var(--background-colorSeven); */
}

.mobile-top-content-mmv-container {
    /* border: 1px solid blue; */
    width: 100%;
    position: relative;
    display: flex;
    gap: 0.5rem;
    justify-content: space-between;
    flex-direction: row-reverse;
}

.mobile-top-content-mmv-container-bg {
    flex-direction: row;
}

.Header-value-set {
    color: var(--text_color_three);
    font-weight: 600;
    text-align: left;
}

.Header-value-set-width {
    /* border: 1px solid red; */
    width: 70%;
}

.Header-value-set>span {
    color: var(--text_color_one);
    font-weight: 400;
    /* margin-left: 5px; */
    /* width: 6rem; */
}

.fake-dropdown-mobile {
    display: flex;
    gap: .5rem;
    cursor: pointer;
}

.fake-dropdown-mobile>p {
    color: var(--primary);
    text-decoration: underline;
}

.tp-classname-for-center {
    flex-direction: row !important;
    justify-content: center !important;
    gap: 0.7rem !important;
}

.mobile-top-content-mmv-container-copy {
    margin-top: 1rem;
}

.mobile-top-content-mmv-container-copy-two {
    padding-left: 1rem;
    gap: 0;
}

.mobile-top-content-mmv-container-copy>* {
    flex: 1 1;
}

.mobile-top-content-mmv-container-bg {
    /* border: 1px solid red; */
    flex-direction: row;
    padding: 1.5rem 1rem;
    background-color: var(--background-colorSeven);
    border-radius: 10px;
}

.mobile-left-content {
    display: flex;
    flex-direction: column;
    gap: .2rem;
}

.head-mobile-topcontent {
    /* border: 1px solid red; */
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: .3rem;
    font-size: .85rem;
    font-weight: 600;
    color: var(--text_color_three);
}

.Header-value-set-flex {
    /* border: 1px solid red; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: .5rem;
}

/* .bottom-tab-sticky {

    background-color: var(--background-colorTwo);
    padding: 0.5rem 0rem;
    padding-bottom: 1rem;
    border-radius: 20px 20px 0px 0px;

} */

.bottom-tab-sticky {
    /* border: 1px solid red; */
    /* background-color: var(--background-colorTwo); */
    padding: 0.5rem 0rem;
    padding-bottom: 1rem;
    border-radius: 20px 20px 0px 0px;
    /* height: 5rem; */
    background: #fff;
    box-shadow: 0px 0px 50px 0px rgb(0 75 243 / 42%);
}

.bottom-tab-sticky-container {
    /* border: 1px solid blue; */
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 1;
    color: white;
    text-align: center;
}

.bottom-tab-sticky-container {
    display: none;
}

.edit-tabs-container-mob-height {
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    inset: 0;
    z-index: 10;
    background: var(--gradient-four);
    backdrop-filter: blur(2px);
}

.edit-tabs-container-mob-height-copy {
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    inset: 0;
    z-index: 11;
    height: 100dvh;
    /* inset: 0; */
    /* z-index: 3; */
    background: var(--gradient-four);
    backdrop-filter: blur(2px);
    overflow: hidden;
}

.edit-tabs-container-mob-height-calendar {
    display: flex;
    justify-content: center;
    align-items: center;
}

.bottom-edit-tabs {
    height: 0;
    /* border: 1px solid red; */
    position: fixed;
    background-color: #fff;
    left: 0;
    bottom: 0;
    z-index: 2;
    width: 100%;
    border-radius: 20px 20px 0px 0px;
    transition: height 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.addons-bottom-edit {
    display: none;
}

.blurred-background {
    filter: blur(8px);
}

.active-bottom-edit-tabs {
    height: 613px;
    border-radius: 20px 20px 0px 0px;
    /* max-height: 999px; */
    box-shadow: var(--boxShadow);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow: scroll;
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    /* /* backdrop-filter: blur(8px); */
}

.active-bottom-edit-policy-tabs {
    border-radius: 20px 20px 0px 0px;
    height: fit-content;
    /* max-height: 999px; */
    box-shadow: var(--boxShadow);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: #fff;
}

.active-bottom-edit-IDV-tabs {
    border-radius: 20px 20px 0px 0px;
    /* height: 18rem; */
    /* max-height: 999px; */
    box-shadow: var(--boxShadow);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: #fff;
}

.active-bottom-edit-ncb-tabs {
    height: fit-content;
    border-radius: 20px 20px 0px 0px;
    box-shadow: var(--boxShadow);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: #fff;
}

.mobile-top-content-mmv-container-copy-two {
    margin-top: 0rem;
}

/* .mobile-left-content-addons {
    align-items: center !important;
} */

.bottom-edit-tabs.active-addons-bottom-edit-tabs {
    height: fit-content;
    /* max-height: 999px; */
    box-shadow: var(--boxShadow);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow: scroll;
    padding-bottom: 0px;
}

.bottom-edit-tabs.active-addons-bottom-edit-tabs-tp {
    height: fit-content;
    /* max-height: 999px; */
    box-shadow: var(--boxShadow);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow: scroll;
    padding-bottom: 0px;
}

.edit-tabs-container-head {
    /* border: 1px solid red; */
    display: flex;
    justify-content: space-between;
}

.edit-tabs-container-head>p {
    color: var(--text_color_three);
    font-size: .9rem;
    font-weight: 500;
}

.edit-tabs-container-head>img {
    cursor: pointer;
}

.Header-value-set-flex-ncb-container {
    display: flex;
    gap: 1rem;
    height: 100%;
    /* padding-left: 1rem; */
}

.Header-value-set-ncb-details {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
}

.Header-value-set-ncb-details>span {
    color: var(--primary);
}

.idv-container-mobile {
    /* border: 1px solid red; */
    /* padding: 1rem 0rem; */
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.text-with-blue-span {
    color: var(--text_color_one);
}

.text-with-blue-span>span {
    color: var(--primary);
    font-weight: 600;
}

.smallTxt-maxMin {
    color: var(--text_color_one);
    font-size: .8rem;
}

.ncb-rect-mob {
    text-align: center;
    border-radius: 3px;
    background-color: #fff;
    border: 1px solid var(--border_color_two);
}

.content-mobile-topcontent-border {
    border: 1px solid var(--border_color_two);
    padding: 10px 10px;
    background-color: #fff;
    border-radius: 3px;
    width: 90%;
    justify-content: space-between;
    position: relative;
}

.content-mobile-topcontent-border>p {
    text-decoration: none;
}

.content-mobile-topcontent-border>img {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}

.calendar-container {
    /* border: 1px solid red; */
    /* height: 30rem;
    width: 50rem; */
    background-color: #fff;
    border-radius: 5px;
    box-shadow: var(--boxShadow);
    padding-bottom: 0.5rem;
}

abbr[title] {
    text-decoration: none !important;
}

.policy-type-list-container-mob {
    /* border: 1px solid red; */
    padding: 20px 0px;
    padding-bottom: 0px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.pol-tab-rect {
    padding: 10px;
    border: 1px solid var(--background-colorThree);
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: all .1s ease-in;
}

.pol-tab-rect.pol-tab-rect-active {
    background-color: var(--background-colorEight);
}

.fake-radio-tab-mobile {
    display: flex;
    /* gap: 10px; */
    align-items: center;
}

.circle-radio-mob {
    border: 1px solid var(--border_color_three);
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--background-color);
    margin-right: .5rem;
}

.circle-radio-mob-child {
    border: 1px solid var(--border_color_three);
    height: 50%;
    width: 50%;
    background-color: #fff;
    border-radius: 50%;
}

.circle-radio-mob-child-active {
    background-color: var(--primary);
}

.radio-label-mobile-clone {
    color: var(--text_color_one);
    font-weight: 600;
}

.radio-label-mobile-clone-desc {
    font-weight: 400;
    padding-left: 2.5rem;
}

.input_group_editForm-mobile {
    display: none;
}

.content_container_vp-disabled {
    align-items: flex-start;
    opacity: .4;
    pointer-events: none;
}

.idvModal-container {
    /* border: 1px solid red; */
    width: 30rem;
    padding: 1.5rem;
    height: 410px;
}

.btnContainer_pform-edit-idv {
    position: fixed;
    bottom: 1.5rem;
}

.head-container-idvTab {
    display: flex;
    /* border: 1px solid blue; */
    justify-content: space-between;
}

.label-value-idv {
    display: flex;
    justify-content: space-between;
    /* border: 1px solid red; */
    width: 100%;
    align-items: center;
}

.label-idvTab {
    color: var(--text_color_three);
    font-size: .9rem;
}

.amt-idvTab {
    color: var(--text_color_three);
    font-weight: 600;
    font-size: .9rem;
}

.circle-radio-mob-idv {
    width: 2.3rem;
}

.idv-tab-input {
    height: 35px;
    border: 1px solid var(--border_color_two);
    border-radius: 4px;
    background: #fff;
    padding: 0px 16px 0 13px;
    outline: 0;
    font-size: 0.9rem;
    color: var(--primary);
    font-weight: 500;
    width: 130px;
}

.idv-topcontent-container>p {
    color: var(--text_color_three);
    font-weight: 600;
    font-size: .9rem;
}

.idv-topcontent-container>p>span {
    font-weight: 500;
}

/* RESPONSIVENESSS----------------------------------------------------------------------- */

@media only screen and (max-width: 610px) {

    .fields-accesories {
        padding: 0rem 1rem;
    }

    .fields-accesories-btn-container-width {
        width: 92%;
        margin: auto;
        margin-top: 1rem;
    }

    .addonContainer__ {
        gap: 10px;
    }

    .input_group_editForm-mobile {
        display: flex;
    }

    .inputGrop-hide-in-mobile {
        display: none;
    }

    .editDetail_container_caseTwo {
        height: auto;
    }

    .err-msg-claim {
        font-size: .7rem;
    }

    .head-confirmDetailContainer-popUp {
        margin-bottom: 0rem !important;
    }

    .ncbBtn-editForm {
        width: 45px;
        font-size: .9rem;
    }

    .customDatePicker {
        width: 150px;
        font-size: .9rem;
    }

    .mobile-left-content-bottom {
        /* border: 1px solid red; */
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .head-mobile-topcontent {
        font-size: .9rem;
    }

    .sumInsuredContainer-fields {
        left: 0rem;
    }

    .inputGroup-accesories {
        flex-direction: column;
        gap: .5rem;
    }

    .accesoriesContainer-fields {
        left: 0;
        width: 100%;
    }

    .tooltip {
        display: none;
    }

    .mobile-top-content-mmv-container-copy-two {
        gap: 1rem;
    }

    .edit-btn-responsive {
        position: absolute;
        right: 0;
    }

    .mobile-top-content-mmv {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;
    }

    .smallFont {
        font-size: 1rem;
    }

    .input_group_editFormTwo {
        padding-bottom: 3rem;
    }

    .editDetail_container-claim {
        padding-top: 1rem !important;
        height: auto;
    }

    .inputGrop-hide-mobile {
        display: none;
    }

    .input_group_editForm>* {
        width: 100%;
    }

    .input_group_editForm {
        gap: 1rem;
        flex-direction: column;
    }

    .editDetail_header {
        display: none;
    }

    .editDetail_container {
        width: auto;
        padding: 0rem;
        gap: 1rem;
    }

    .mobile-top-content-container {
        display: block;
    }

    .viewPlans_content_container {
        display: none;
    }

    .plansContainer_viewPlan_parent {
        bottom: 0rem;
    }

    .topContent_vp {
        display: none;
    }

    .bottomContent_header_vp_one {
        display: none;
    }

    .planSelectorContainer {
        display: none !important;
    }

    .section_bottomContent_vpOne {
        display: none;
    }

    .dropdown-viewPlans-container-mobile {
        display: none;
    }

    .plansContainer_viewPlan {
        padding: 0rem 1rem;
    }

    .bottomContent_container_vp {
        padding: 0rem;
    }

    .bottomContent_header_vp_two {
        justify-content: center;
        padding: 0rem;
    }

    .plansContainer_viewPlan_parent {
        /* border: 1px solid red; */
        background-color: transparent;
        box-shadow: none;
    }

    .section_bottomContent_vpTwo {
        width: 100%;
    }

    .title_vp_flex-mobile {
        display: flex;
    }

    .bottom-tab-sticky-container {
        display: block;
    }

}

@media only screen and (min-width: 611px) and (max-width: 2000px) {
    .edit-tabs-container-mob-height {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .active-bottom-edit-policy-tabs {
        bottom: auto;
        width: fit-content;
        border-radius: 20px 20px 20px 20px;
        width: 28rem;
    }

    .edit-tabs-container-head-ncb {
        padding: 0rem 2rem;
        padding-top: 1rem;
    }

    .btnContainer_pform-edit {
        padding-bottom: .5rem !important;
    }

    .active-bottom-edit-ncb-tabs {
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
        width: fit-content;
        margin: auto;
        border-radius: 10px;
        /* margin-left: auto;
        margin-right: auto; */
    }
}