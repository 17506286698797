.css-d7l1ni-option {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
    background-color: #fff !important;
    cursor: pointer !important;
}

.css-d7l1ni-option:hover {
    background-color: #fff !important;
    cursor: pointer !important;
}

.css-tr4s17-option {
    background-color: #fff !important;
    color: #000 !important;
}

.css-13cymwt-control:focus {
    border-color: #fff !important;
    border-width: 0px !important;
}

.css-t3ipsp-control:hover {
    border-color: #fff !important;
    border-width: 0px !important;
}

.css-1nmdiq5-menu {
    border: 1px solid var(--border_color_two) !important;
    border-radius: 1px !important;
    box-shadow: none !important;
    margin-top: -2px !important;
    border-top: 0 !important;
}

.css-mkcgli-control {
    min-height: 52px !important;
    height: 52px !important;
}

.css-1u9des2-indicatorSeparator {
    width: 0px !important;
}

.css-1n6sfyn-MenuList {
    max-height: 182px !important;
    overflow-x: hidden !important;
}

.css-1jqq78o-placeholder {
    color: var(--text_color_two) !important;
}

.search-select-container {
    position: relative;
    /* border: 1px solid red; */
}

.label-dropdown-search {
    top: -9px;
}

.modifiy-label-css {
    left: -9px;
}

.css-18fq7r3-control {
    background-color: #fff !important;
}

.css-olqui2-singleValue {
    color: hsl(0, 0%, 20%) !important;
}

.css-894a34-indicatorSeparator{
    width: 0px !important;
}