.PropsalFormContainer {
    padding: 3rem 4rem;
    position: relative;
    padding-top: 0rem;
    background-image: url('../../Assets/Images/Background.jpg');
    background-repeat: no-repeat;
    background-size: 100% 20rem;
}

.PropsalFormContainer_padding {
    padding-top: 2rem;
}

.PropsalFormContainer_parent {
    /* border: 1px solid blue; */
    display: flex;
    box-shadow: var(--boxShadow);
    border-radius: 15px;
    background-color: #fff;
    justify-content: space-between;
    margin-bottom: 3rem;
}

.footer_bottom_container_pForm {
    margin-bottom: 1.5rem !important;
    padding-bottom: 1.5rem !important;
}

.padding_PropsalFormContainer_parent {
    padding: 1rem 2rem;
    flex-direction: column;
}

.breakupContainer {
    width: 29%;
    max-width: 310px;
    display: flex;
    border-radius: 15px;
    background: var(--gradient-nine);
    /* border: 1px solid red; */
}

.formContainer {
    width: 76%;
    padding: 1rem 2rem;
}

.bg {
    /* border: 1px solid blue; */
    height: 50%;
    position: absolute;
}

.stepperContainer {
    /* border: 1px solid green; */
    padding: 1rem;
    display: flex;
    justify-content: flex-start;
    gap: .5rem;
    padding-left: 0rem;
}

.stepperDiv {
    /* border: 1px solid red; */
    background-color: var(--background-color);
    cursor: pointer;
    padding: .5rem;
    display: flex;
    gap: 1rem;
    min-width: 11rem;
    /* border: 1px solid SILVER; */
    box-shadow: var(--boxShadowTwo);
    border-radius: 10px;
}

.stepperDiv_active {
    background-color: #fff;
}

.signal_pform {
    /* border: 1px solid blue; */
    width: 2rem;
    color: var(--text_color_three);
    display: flex;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    background-color: var(--border_color_three);
}

.small_text_stepper {
    font-size: .75rem;
}

.title_stepper>p:last-child {
    font-size: .8rem;
    color: var(--text_color_three);
}

.premiumContainer {
    padding: 14px 14px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    /* border: 1px solid; */
    width: 100%;
    border-radius: 15px;
    background: var(--gradient-nine);
}

.pol-details-premiumContainer {
    /* border: 1px solid red; */
    background-color: #fff;
    border-radius: 5px;
    /* border: 1px solid blue; */
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.car-details-premContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    /* justify-content: space-between; */
    align-items: center;
    border-bottom: 1px solid var(--border_color_three);
    padding: 10px 0px;
    padding-top: 5px;
}

.car-details-premContainer>p:first-child {
    background-color: var(--border_color_three);
    padding: 2px 5px;
    /* font-size: .7rem; */
    border-radius: 5px;
    color: var(--text_color_four);
    font-weight: 500;
}

.car-details-premContainer>p:last-child {
    color: var(--text_color_four);
    font-weight: 500;
}

.policy-det-premContent {
    display: flex;
    gap: 2rem;
    /* justify-content: space-between; */
}

.mmv_premiumContainer {
    /* border: 1px solid green; */
    display: flex;
    flex-direction: column;
    padding: 10px 0px;
    padding-top: 0px;
    border-bottom: 1px solid var(--border_color_three);
    /* gap: 5px; */
}

.policy-det-premContent-block>p:first-child {
    color: var(--text_color_one);
    font-weight: 500;
}

.policy-det-premContent-block>p:last-child {
    color: var(--text_color_three);
    font-weight: 500;
}

.mmv_premiumContainer>p:first-child {
    font-size: 1rem;
    font-weight: 600;
    color: #000;
}

.mmv_premiumContainer>p:last-child {
    font-size: .8rem;
    font-weight: 500;
    color: var(--text_color_three);
}

.amountContainer {
    display: flex;
    /* border: 1px solid red; */
    justify-content: space-between;
    gap: 5px;
    padding: 10px 7px;
    align-items: center;
    background-color: #fff;
    border-radius: 5px;
}

.amountContainer>img {
    width: 5rem;
}

.amt_prem>:last-child {
    font-size: .7rem;
}

.amt_prem>:first-child {
    font-size: 1rem;
    font-weight: 800;
}

.amt_prem {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.breakup_headValue {
    display: flex;
    justify-content: space-between;
    gap: 3px;
    align-items: center;
}

.breakup_headValue>p:first-child {
    width: 70%;
    color: var(--text_color_one);
    font-size: .8rem;
    word-break: break-word;
}

.breakup_headValue>p:last-child {
    width: 30%;
    font-size: .8rem;
    text-align: right;
    word-break: break-word;
    color: var(--text_color_three);
}

.titleContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.titleContainer_pform {
    margin-bottom: 2rem;
}

.titleContainer>p:first-child {
    font-size: .9rem;
    color: #000 !important;
    font-weight: 500;
}

.titleContainer>p:last-child {
    font-size: .8rem;
    color: var(--redTxt);
}

.checkBox_form {
    padding: 2rem 0rem;
    padding-bottom: 0rem;
}

.input_group_pform {
    /* border: 1px solid red; */
    display: flex;
    gap: 2rem;
    position: relative;
}

.input_group_pform-unique-kyc {
    display: flex;
    gap: 2rem;
    position: relative;
}

.input_group_pform>* {
    flex: 1 1;
}

.formSection-pform {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.kyc_info_container {
    border: 1px solid var(--primary);
    padding: 1rem 1rem;
    padding-right: 2rem;
    background-color: var(--border_color_three);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.btn_pForm {
    width: 11rem;
    height: 3.2rem;
    /* margin-top: 1rem; */
}

.btnContainer_pform {
    display: flex;
    justify-content: flex-start;
    gap: 1.5rem;
    padding-bottom: 2rem;
}

.kyc_container_photo_conteent {
    /* border: 1px solid red; */
    display: flex;
    gap: 1.5rem;
    position: relative;
    padding-bottom: 2rem;
    justify-content: space-between;
}

.bottomInfo_kyc {
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: .8rem;
    color: var(--text_color_three);
}

.bottomInfo_kyc-two {
    font-size: .8rem;
    color: var(--text_color_three);
}

.kyc_container_content {
    width: 80%;
    /* padding: 1rem; */
    /* border: 1px solid green; */
}

.kycFormContainer {
    display: flex;
    /* width: 75%; */
    flex-direction: column;
    padding: 1rem 0rem;
    gap: 2rem;
    /* border: 1px solid blue; */
}

.title_kyc_pf {
    font-size: .9rem;
    font-weight: 500;
}

.kyc_container_photo {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--primary);
    flex-direction: column;
    cursor: pointer;
    border-radius: 5px;
    gap: 1rem;
    /* width: 20%; */
    padding: 1rem;
    max-height: 148px;
    background-color: var(--border_color_three);
}

.kyc_container_photo-err {
    border: 1px solid red !important;
}

.kyc_container_photo_img {
    width: 20%;
    border-radius: 5px;
    height: 150px;
    aspect-ratio: 2 / 3;
}

.kyc_container_photo>img {
    width: 4.1rem;
}

.profileImg-kyc {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    object-fit: cover;
}

.kyc_container_photo>p {
    /* border: 1px solid red; */
    font-size: .8rem;
    text-align: center;
    color: var(--text_color_four);
}

.inputGrop_caseTwo {
    flex-direction: column;
}

.customLabel_pform {
    font-size: .9rem;
    font-weight: 500;
    margin-bottom: .7rem;
}

.radioContainer-pform {
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
}

.nominee_division {
    display: flex;
    /* border: 1px solid red; */
    gap: 1rem;
}

.nominee_division_age {
    width: 20%;
}

.nominee_division_Relation {
    width: 80%;
}

.Input_Dropdown {
    border: 0px;
    outline: 0px;
    font-size: .8rem;
    width: 100%;
    color: var(--text_color_three);
    cursor: pointer;
}

#relParentDp {
    font-size: .9rem;
    color: var(--text_color_three);
    cursor: pointer;
}

#prevInsListDp {
    font-size: .9rem;
    color: var(--text_color_three);
    cursor: pointer
}

#cityListDp {
    font-size: .9rem;
    color: var(--text_color_three);
    cursor: pointer
}

#stateParentDp {
    font-size: .9rem;
    color: var(--text_color_three);
    cursor: pointer
}

.headTxtClassPform {
    font-size: .8rem !important;
    color: var(--text_color_two) !important;
}

.inputGropKyc-custom {
    border: 1px solid var(--border_color_two);
    height: 52px;
    display: flex;
    justify-content: space-between;
    padding-left: 1rem;
    align-items: center;

    border-radius: 5px;
}

.inputGropKyc-custom>p {
    font-size: .8rem;
    color: var(--text_color_two);
    pointer-events: none;
}

.btn-kyc- {
    height: 100%;
    border: none;
    text-transform: capitalize;
    outline: none;
    color: #000;
    display: flex;
    cursor: pointer;
    font-size: .9rem;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: var(--border_color_two);
    border-radius: 5px;
}

.customLabel-kyc {
    width: 35%;
    height: 100%;
}

.input_group_pform-kyc {
    /* border: 1px solid red; */
    padding-bottom: 2.5rem;
    position: relative;
}

.input_group_pform-kyc-tata {
    padding-bottom: 0rem;
}

.msg-kyc {
    display: flex;
    position: absolute;
    bottom: 0;
    width: 100%;
    justify-content: space-between;
}

.msg-kyc>* {
    flex: 1 1;
}

.input_group_pform-flex {
    gap: 3rem;
}

.inputGrop_caseThree {
    padding-bottom: 1.5rem;
}

.edit_btn_pform {
    position: absolute;
    right: 0;
}

.hideWithOpacity {
    /* opacity: 0; */
    visibility: hidden;
    /* border: 1px solid red; */
    height: 2rem;
    overflow: hidden;
}

.kyc-succes-upload {
    color: var(--greenText);
}

#kycPanNumberr {
    width: 47% !important;
}

#kycAadharNumber {
    width: 47% !important;
}

#uploadImg-Aadharr {
    width: 47% !important;
}

#uploadImg-PANn {
    width: 47% !important;
}

.gross-prem-block {
    background: #b1c9ff;
    padding: 3px 10px;
    border-radius: 5px;
    font-weight: 700;
}

.bold-content {
    font-weight: 700;
}

input[type="text"] {
    text-transform: capitalize;
}

.label-container {
    border-radius: 5px;
    width: 20%;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.view-breakup-btn {
    text-align: left;
    position: relative;
    color: var(--primary);
    cursor: pointer;
}

.view-breakup-btn-non-relative {
    top: 0;
}

.breakup-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    /* border: 1px solid red; */
    padding: 10px;
    position: relative;
    background-color: #fff;
    border-radius: 5px;
    transition: all 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.breakup_headValue-bold>p:first-child {
    /* border: 1px solid red; */
    color: var(--text_color_one);
    font-weight: 600;
}

.breakup_headValue-bold>p:last-child {
    font-weight: 600;
}

.breakup_headValue-bold-border {
    border-bottom: 1px solid var(--border_color_three);
    padding-bottom: 5px;
}

.breakup-container-od {
    height: 0;
    overflow: hidden;
    /* display: none; */
    transition: height 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.breakup-container-od.breakup-container-od-extended {
    height: 100%;
}

.breakup-container.expand-breakup-container {
    height: 18.5rem;
    overflow: auto;
}

.PremiumContainer-div {
    width: 100%;
    display: flex;
    height: fit-content;
    position: sticky;
    top: .5rem;
}

.mobile-back-pform {
    margin-bottom: .6rem;
    position: absolute;
    top: 15px;
}

.blank-checkBox-div {
    padding: 0;
    height: 0rem;
}

.edit_btn_pform-mobile {
    display: none;
}

.title-editbtn-container-collapsible {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
}

.adaptive-breakup-proposal {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.view-breakup-btn-adaptive {
    text-align: center;
    display: none;
    margin-bottom: 1rem;
}

.acko-creative-container {
    border-radius: 10px;
    height: 15rem;
    display: flex;
    justify-content: space-between;
    background: linear-gradient(96.21deg, #714091 0.44%, #9A67AC 65.62%, #603876 98.57%);
}

.acko-creative-img {
    height: 100%;
}

.acko-creative-img>img {
    height: 100%;
    padding-right: .5rem;
}

.acko-creative-content {
    padding: 1.3rem;
}

.acko-creative-content>p {
    color: #fff;
    font-size: 1.3rem;
    font-weight: 600;
}

.acko-logo-container {
    margin-top: 1rem;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.acko-logo-container>p {
    text-transform: uppercase;
    color: #fff;
    font-size: 1rem;
    font-weight: 500;
}

.acko-logo-creative {
    width: 4rem;
    margin-bottom: 10px;
}

.acko-logo-container>div>p {
    color: #fff;
    font-size: .9rem;
}

.download-container {
    display: flex;
    gap: 10px;
}

.download-container-img {
    color: #fff;
    align-items: center;
    display: flex;
    gap: 4px;
}

.download-container>p {
    font-weight: 500;
}

/* RESPONSIVENESSSSSSSS---------------------------------------------------------------- */

@media only screen and (max-width: 1174px) {
    .acko-creative-container {
        display: none;
    }
}

@media only screen and (max-width: 610px) {

    .btn_pForm {
        width: 100%;
    }

    .premiumContainer {
        padding-bottom: 0px;
    }

    .collapsibleTab-column {
        flex-direction: column !important;
    }

    .edit_btn_pform-mobile {
        display: flex;
    }

    .edit_btn_pform {
        display: none;
    }

    #kycPanNumberr {
        width: 100% !important;
    }

    #kycAadharNumber {
        width: 100% !important;
    }

    .kyc_container_photo_img {
        width: 100%;
    }

    .label-container {
        width: 100%;
    }

    .kyc_container_photo_conteent {
        flex-direction: column;
        gap: 1rem;
    }

    .kyc_container_content {
        width: 100%
    }

    .stepperContainer {
        padding: 1rem;
    }

    .headTxtClassPform {
        font-size: 1rem !important;
    }

    .main-proposalForm-container {
        overflow: hidden;
    }

    .Input_Dropdown {
        font-size: 1rem;
    }

    .hideWithOpacity {
        display: none;
    }

    .titleContainer_pform {
        margin-bottom: 1rem;
    }

    .view-breakup-btn {
        display: block;
    }

    /* .breakup-container {
        height: 0;
        overflow: hidden;
    } */

    .formSection-pform {
        gap: 1rem;
    }

    .btnContainer_pform>* {
        flex: 1 1;
    }

    /* .stepperWithForm {
        padding-top: 2rem;
    } */

    .PropsalFormContainer_parent {
        flex-direction: column;
        margin-bottom: 2rem;
    }

    .PropsalFormContainer {
        background-image: none;
        padding: 1rem 1rem;
    }

    .breakupContainer {
        width: 100%;
        max-width: none;
    }

    .input_group_pform {
        flex-direction: column;
        gap: 1rem;
    }

    .formContainer {
        width: 100%;
        padding: 1rem 1rem;
    }

    .titleContainer {
        flex-direction: column;
        gap: .5rem;
    }

    .checkBox_form {
        padding: 1rem 0rem !important;
        padding-bottom: 0rem !important;
    }

    .btnContainer_pform {
        padding-bottom: 0rem;
    }

    .btnContainer_pform-cnfrmDetails {
        position: sticky;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0rem -1rem;
        background: var(--background-colorTwo);
        padding: 1rem 1rem;
        border-radius: 20px 20px 0px 0px;
    }
}

@media only screen and (max-width: 1038px) {
    .stepperContainer {
        pointer-events: none;
        opacity: 0;
        height: 1rem;
        /* display: none; */
    }
}