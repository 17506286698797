.vertical-timeline {
    position: relative;
    width: 100%;
    padding-left: 2rem;
}

.timeline-line {
    position: absolute;
    width: 8px;
    height: 100%;
    border-radius: 10px;
    background-color: #fff;
}

.timeline-item {
    position: relative;
    display: flex;
    align-items: center;
    padding: 20px;
    height: 10rem;
}

.circle {
    width: 50px;
    height: 50px;
    background-color: #fff;
    color: #000;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    position: absolute;
    left: -22px;
    filter: drop-shadow(0px 4px 25px rgba(80, 134, 255, 0.25));
}

.timeline-content {
    margin-left: 40px;
    padding: 1.5rem;
    border-radius: 20px;
    position: relative;
    display: flex;
    gap: 1rem;
    width: 30rem;
    background-color: #fff;
}

.timeline-content>img {
    width: 2.5rem;
}

.small-timeline-icon {
    width: 2rem !important;
}

.timeline-content:after {
    content: '';
    position: absolute;
    left: -10px;
    top: 43%;
    margin: 0 auto;
    width: 0;
    height: 0;
    border-top: solid 10px transparent;
    border-right: solid 10px #fff;
    border-bottom: solid 10px transparent;
}

.timeline-content h3 {
    margin-top: 0;
}

.timeline-content p {
    margin-bottom: 0;
}

.title-timeline {
    font-size: 1rem;
    color: #000;
}

.desc-timeline {
    margin-top: 5px;
    color: var(--text_color_one);
}

.small-timeline-icon-two {
    width: 2.7rem !important;
}