.planSelectorContainer {
  /* border: 1px solid violet; */
  height: 3rem;
  display: flex;
  position: sticky;
  /* border: 1px solid; */
  background: #fff;
  margin: 1rem 0rem;
  top: 0px;
  z-index: 2;
  gap: 1rem;
  justify-content: space-between;
}

.planSelectorContainer-bike {
  justify-content: flex-start;
}

.idv-topcontent-container {
  width: 25%;
  border-radius: 5px;
  border: 1px solid var(--background-colorThree);
  /* border: 1px solid blue; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  cursor: pointer;
}

.policyTypeOption-container {
  /* border: 1px solid red; */
  display: flex;
  width: 75%;
}

.planSelectorNonSticky {
  height: 3rem;
  display: flex;
}

.plancard_main_container {
  /* border: 1px solid red; */
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 2%;
}

.plancard_card {
  border: 1px solid var(--border_color_two);
  border-radius: 10px;
  width: 32%;
  max-width: 290px;
  min-width: 230px;
  margin-bottom: 1rem;
  height: fit-content;
}

.inspection_tab {
  background-color: #6B7385;
  color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  padding: 5px 10px;
  border-radius: 5px;
}

.inspection_tab-green {
  background-color: var(--greenTwo);
}

.inspection_tab>p {
  font-size: .75rem;
}

.inspection_tab>img {
  width: 1.2rem
}

.padding_breakup_container {
  padding: .7rem .7rem;
  display: flex;
  border-radius: 10px 10px 0px 0px;
  background-color: #fff;
  /* background: var(--gradient-three); */
  flex-direction: column;
  gap: 10px;
}

.plancard_card_img_holder {
  /* border: 1px solid orange; */
  /* width: 6rem; */
  height: 4.5rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.plancard_card_img_holder>img {
  /* width: 100%; */
  height: 100%;
  border-radius: 5px;
  /* mix-blend-mode: multiply; */
}

.premiumContainer_pc {
  width: 100%;
  padding: 5px 5px;
  /* border: 1px solid red; */
  background-color: var(--background-colorNine);
  display: flex;
  border-radius: 5px;
  flex-direction: column;
  gap: 10px;
}

.idv_title_pc {
  color: var(--text_color_one);
  display: flex;
  font-size: .9rem;
  font-weight: 500;
  padding: 0px 5px;
  gap: .2rem;
  /* border: 1px solid red; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.idv_title_pc>span {
  /* border: 1px solid blue; */
  display: flex;
  align-items: center;
  gap: .5rem;
  font-size: .9rem;
  font-weight: 500;
  color: var(--text_color_three);
}

.buyNowBtn_pc {
  background: var(--primary);
  border: none;
  outline: none;
  width: 100%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 5px;
  height: 3.5rem;
  cursor: pointer;
  font-size: 1.2rem;
  transition: all .1s ease;
  font-weight: 700;
}

.buyNowBtn_pc>span {
  /* color: ; */
  font-size: 0.8rem;
  font-weight: 500;
}

.buyNowBtn_pc:hover {
  background-color: #2C5ECD;
}

.breakup_container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.planHeadValueContainer {
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.planHead>p {
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
  font-weight: 500;
  color: var(--text_color_one);
}

.planHead>p>span {
  color: var(--text_color_three);
  font-weight: 500;
}

.bold_premium {
  font-weight: 700 !important;
}

.card_msg_pc {
  font-size: 0.8rem;
  color: var(--text_color_three);
}

.bottomOptions_planCard {
  background-color: var(--background-colorThree);
  /* background-color: var(--primary); */
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  height: 3rem;
  padding: 10px 0px;
  border-radius: 0px 0px 10px 10px;
}

.bottomOptions_planCard>* {
  flex: 1 1;
}

.bottomOptions_planCard>.option_div_pc:last-child {
  display: none;
}

.option_div_pc>p {
  height: 100%;
  color: #000 !important;
  font-size: 0.75rem !important;
  font-weight: 500;
  width: 100%;
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.option_div_pc {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.7rem;
}

.option_div_pc_border {
  /* border-right: 1px solid #fff; */
  color: #fff;
}

.modal-plandetails {
  width: 58rem;
  justify-content: space-between;
}

.ins-card-planDetails {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 10px;
  /* border: 1px solid red; */
  border-radius: 5px;
  background-color: #fff;
  gap: 2rem;
  margin-bottom: 2rem;
}

.ins-card-planDetails>img {
  width: 5rem;
}

.ins-card-planDetails>p {
  font-weight: 700;
  font-size: 0.9rem;
  margin-bottom: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.ins-card-planDetails>p>span {
  color: var(--text_color_one);
  font-size: 0.75rem;
  font-weight: 400;
}

.leftSide_modal-plandetails {
  display: flex;
  flex-direction: column;
  gap: 0rem;
  /* width: 35%; */
  padding: 1.5rem;
}

.breadCrumb-planDetails {
  /* border: 1px solid red; */
  height: 3.3rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  font-size: 0.8rem;
  width: 13.5rem;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  font-weight: 500;
  border-radius: 5px;
}

.breadCrumb-planDetails-active {
  box-shadow: var(--boxShadowTwo);
  background: #fff;
}

.rightSide_modal-plandetails {
  /* border: 1px solid red; */
  /* width: 60%; */
  padding: 1.5rem;
  padding-right: 2.3rem;
}

.coveredDetails-plandetails {
  border: 1px solid var(--border_color_two);
  padding: 1rem 1.5rem;
  border-radius: 5px;
  width: 34rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
  height: 21rem;
  margin-bottom: 0.5rem;
  overflow: auto;
}

.coveredDetails-plandetails-breakup {
  gap: .5rem;
}

.details-covered {
  /* border: 1px solid blue; */
  display: flex;
  border-bottom: 1px solid var(--border_color_three);
  padding-bottom: 13px;
  justify-content: space-between;
  align-items: center;
}

.details-covered:last-child {
  border: none;
}

.details-covered>p {
  font-size: 0.85rem;
  color: var(--text_color_one);
}

.details-covered-breakup-section {
  border-bottom: 0;
  padding-bottom: 0;
}

.details-covered-breakup-section-highlighted {
  border: 1px solid var(--border_color_three) !important;
  margin-bottom: 1rem;
  background-color: var(--background-colorSix);
  padding: 5px 10px;
  border-radius: 5px;
}

.details-covered-breakup-section-highlighted>p {
  color: var(--text_color_one);
  font-weight: 600;
}

.title-rightside-plandetails {
  font-size: 0.9rem;
  font-weight: 500;
  /* margin-top: 1rem; */
}

.closeImg_modal-plandetails {
  right: 1.4rem;
  top: 1.4rem;
}

.section-planDetails {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.amt-breakup-plandetails {
  color: var(--text_color_three) !important;
  /* font-weight: 500; */
}

.confirmDetailContainer-popUp {
  /* border: 1px solid red; */
  width: 50rem;
  /* height: 88dvh; */
  padding: 1rem;
  position: relative;
}

.head-confirmDetailContainer-popUp {
  /* border: 1px solid red; */
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
}

.head-confirmDetailContainer-popUp>p {
  color: #000;
  font-size: 1.05rem;
  font-weight: 500;
  /* margin-bottom: 1rem; */
}

.head-confirmDetailContainer-popUp>img {
  cursor: pointer;
}

.form-cnfrmDetails-popup {
  border-radius: 5px;
  border: 1px solid var(--background-colorThree);
  padding: 0.5rem;
  height: 54dvh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  /* gap: 1rem; */
  position: relative;
}

.form-cnfrmDetails-inputGrp {
  /* border: 1px solid red; */
  padding: 10px;
  display: flex;
  gap: 2rem;
  align-items: flex-start;
}

.od-msg {
  color: var(--text_color_three);
  font-size: .65rem;
}

.form-btn-inputGrp-div {
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 0.5rem;
}

.form-btn-inputGrp {
  gap: 1rem;
  /* border: 1px solid red; */
  display: flex;
}

.form-cnfrmDetails-inputGrp>* {
  flex: 1 1;
  /* border: 1px solid blue; */
}

.cnfrmDetails-inputGrp-container {
  /* border: 1px solid blue; */
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

/* .calender-cnfrm-popup {
  width: 150px;
} */

.label-popUp {
  font-size: 0.8rem;
  font-weight: 500;
}

.label-popUp-two {
  margin-bottom: 0.5rem;
}

.customDatePicker-popUp {
  margin-top: 0;
  width: 150px;
  font-size: .9rem;
  background-color: var(--border_color_three);
  border: 1px solid var(--border_color_two);
  color: var(--primary);
}

.dropdown-popUp {
  width: 150px;
  height: 30px;
}

.dropDownHead-popUp {
  display: flex;
  justify-content: space-between;
  height: 30px;
  align-items: center;
  padding: 0rem 1rem;
  border-radius: 5px;
  background-color: var(--border_color_three);
  cursor: pointer;
}

.input-dp-popup {
  border: 0px;
  outline: 0px;
  font-size: 0.9rem;
  background-color: var(--border_color_three);
  width: 100%;
  color: var(--primary);
  cursor: pointer;
}

.dpGrp-popUp {
  display: flex;
  height: 100%;
  width: 100%;
  gap: 1rem;
}

.dpGrp-popUp-gap {
  gap: 2rem;
}

.dpGrp-popUp>* {
  flex: 1 1;
}

#arrowInGray {
  width: 0.8rem;
}

.polType-btn-popUp {
  border: 1px solid var(--text_color_two);
  min-width: 92px;
  font-size: .9rem;
  padding: 0px 15px;
  height: 30px;
  background-color: #fff;
  color: var(--text_color_two);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
}

.polType-btn-popUp-active {
  background-color: var(--border_color_three);
  border: 1px solid var(--border_color_two);
  color: var(--primary);
}

.checkbox_container-popUp {
  padding: 10px;
  display: flex;
  align-items: center;
}

.hr-popUp {
  border: 1px solid var(--border_color_three);
  margin: 10px 0px;
}

.input_group_editFormThree {
  /* border: 1px solid red; */
  height: 10rem;
}

.btnContainer_pform-cnfrmDetails {
  /* width: 100%; */
  /* border: 1px solid red; */
  padding: 1rem;
  background-color: #fff;
  padding: 0rem 1rem;
  padding-bottom: 10px !important;
}

.msgIn-red {
  font-size: 0.75rem;
  color: red;
  font-weight: 300;
}

.inputGrop_caseThreee {
  position: relative;
  bottom: 10px;
}

.breakup-head-planDetails {
  /* border: 1px solid red; */
  padding-bottom: 3px;
}

.breakup-head-planDetails>p:first-child {
  color: var(--text_color_three);
  font-weight: 600;
}

.breakup-head-planDetails>p:last-child {
  color: var(--text_color_one);
  font-weight: 600;
}

.breakup-head-planDetails>p:last-child>span {
  color: var(--text_color_three);
}

.btnContainer_pform-cnfrmDetails-Mobile {
  display: none !important;
}

.covered-details-container {
  width: 33rem;
  height: 24rem;
  /* border: 1px solid red; */
  overflow: auto;
  padding-right: 1rem;
  margin-right: 1rem;
}

.covered-details-container-title {
  font-size: 1.2rem;
  font-weight: 500;
  margin: 5px 0px;
  color: var(--text_color_three);
}

.covered-details-container-desc {
  color: var(--text_color_one);
  font-weight: 400;
}

.list-coverages- {
  /* border: 1px solid blue; */
  margin: 14px 0px;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.title-list-coverages {
  /* border: 1px solid green; */
  display: flex;
  align-items: center;
  gap: 8px;
}

.title-list-notCovered>p {
  color: var(--redTxt_two) !important;
}

.title-list-coverages>p {
  color: var(--text_color_three);
  font-weight: 500;
  font-size: .85rem;
}

.list-coverages->p {
  color: var(--text_color_one);
  font-weight: 400;
  padding-left: 20px;
}

/* RESPONSIVENESS --------------------------------------------------> */

@media only screen and (max-width: 610px) {

  .cnfrmDetails-inputGrp-container {
    width: 99%;
  }

  .covered-details-container {
    height: 83%;
    width: 100%;
  }

  .section-planDetails {
    height: 100%;
  }

  .btnContainer-planDetails {
    background-color: var(--background-colorTwo);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem;
    padding-bottom: 1rem !important;
    border-radius: 20px 20px 0px 0px;
  }

  .ins-card-planDetails {
    padding: 10px 10px;
    border: 1px solid var(--border_color_five);
  }

  .leftSide_modal-plandetails {
    background: #fff;
  }

  .breadCrumb-planDetails-active {
    box-shadow: none;
    background: transparent;
    border-bottom: 2px solid var(--primary) !important;
  }

  .breadCrumb-planDetails {
    border-radius: 0%;
    height: auto;
    width: fit-content;
    padding: 7px 10px;
    border-bottom: 2px solid var(--background-colorThree);
  }

  .breadCrumb-planDetails>p {
    width: max-content;
  }

  .breakup-head-planDetails {
    gap: 1rem;
  }

  .breadcrumb-container-plandetails {
    display: flex;
    overflow: auto;
  }

  .breadcrumb-container-plandetails::-webkit-scrollbar {
    display: none;
  }

  .coveredDetails-plandetails {
    padding: 1rem 1rem;
    height: 78%;
    margin-bottom: 0;
    border: none;
  }

  .hr-popUp {
    display: none;
  }

  .btnContainer_pform-cnfrmDetails-Mobile {
    display: flex !important;
  }

  .btnContainer_pform-cnfrmDetails-deskTop {
    display: none !important;
  }

  .head-confirmDetailContainer-popUp>p {
    margin-bottom: 1rem;
  }

  .polType-btn-popUp {
    font-size: 0.9rem;
  }

  .ins-card-planDetails {
    margin-bottom: 10px;
  }

  .leftSide_modal-plandetails {
    padding: 1rem;
  }

  .title-rightside-plandetails {
    display: none;
  }

  .rightSide_modal-plandetails {
    padding: 1rem;
    height: 100%;
  }

  .polType-btn-popUp {
    font-size: .9rem;
  }

  .head-confirmDetailContainer-popUp>img {
    display: none;
  }

  .option_div_pc>p {
    font-size: 0.9rem !important;
  }

  .form-btn-inputGrp {
    flex-wrap: wrap;
  }

  .form-btn-inputGrp-div {
    padding: 0px;
  }

  .confirmDetailContainer-popUp {
    width: 100dvw;
    height: 100dvh;
  }

  .form-cnfrmDetails-popup {
    gap: 1rem;
    padding: 0rem;
    border: none;
    /* border: 1px solid red; */
    height: fit-content;
    /* max-height: 80%; */
    margin-bottom: 1rem;
  }

  .plancard_main_container {
    justify-content: center;
  }

  .plancard_card {
    min-width: none;
    max-width: none;
    width: 100%;
  }

  .premiumContainer_pc {
    padding: 10px 10px;
  }

  .modal-plandetails {
    width: 100dvw;
    justify-content: flex-start;
  }

  .coveredDetails-plandetails {
    width: 100%;
    max-height: none;
  }

  .form-cnfrmDetails-inputGrp {
    padding: 0px;
    gap: 1rem;
    flex-direction: column;
  }

  .checkbox_container-popUp {
    padding: 10px 0px;
    padding-bottom: 0px;
  }
}

@media only screen and (max-width: 610px) and (min-width: 390px) {
  .coveredDetails-plandetails {
    height: 90%;
    /* border: 1px solid red; */
  }
}

@media only screen and (max-width: 390px) {
  .confirmDetailContainer-popUp {
    width: 100vw;
    height: 100dvh;
  }
}

@media only screen and (max-width: 1426px) and (min-width: 1101px) {
  .plancard_card_tp {
    width: 30%;
    max-width: 254px;
    min-width: 230px;
  }
}