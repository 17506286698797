.loader-container {
    border: 1px solid red;
    height: 88vh;
    display: flex;
    /* background: var(--gradient-one); */
    justify-content: center;
    align-items: center;
}

.loader-container>img {
    mix-blend-mode: multiply;
}

.loader-bmi {
    height: 200px;
    width: 200px;
}