.calendar-container-head {
    /* border: 1px solid red; */
    padding: 1rem 1.3rem;
    position: relative;
    width: 100%;
    border-bottom: 1px solid var(--border_color_two);
}

.calendar-container-head>p {
    font-size: .9rem;
    font-weight: 500;
    /* border: 1px solid red; */
    width: 271px;
    color: var(--text_color_three);
    text-align: left;
    margin: auto;
}

.calendar-container-head>img {
    position: absolute;
    right: 26px;
    top: 50%;
    width: 0.9rem;
    cursor: pointer;
    transform: translateY(-50%);
}

.calendar-btn-container {
    padding: 1rem 0rem;
    /* border: 1px solid red; */
    display: flex;
    gap: 1rem;
    width: 271px;
    margin: auto;
}

.calendar-btn {
    width: 131px;
    height: 44px;
}

.pForm_datePicker-tab {
    /* border: 1px solid red; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.pForm_datePicker-tab>img {
    width: 1.4rem;
}

.css-jgls56-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected) {
    border: none !important;
}

.calendarTxt-notSure {
    text-align: center;
    /* border: 1px solid; */
    display: flow;
    margin-bottom: 0.5rem;
}

.alternate-options-expiry {
    /* border: 1px solid red; */
    height: 404px;
    width: 320px;
    margin: auto;
    padding: .5rem 1.3rem;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.alternate-options-expiry>p{
    color: var(--text_color_one);
    font-size: .9rem;
    font-weight: 500;
    margin: 0px 3px;
}

.calendar-container-head-noBorder{
    border-bottom: 0;
}

.seprator-calendar {
    /* margin-top: 10px; */
    border: 1px solid var(--border_color_six) ;
    /* margin: 5px 0px; */
}

.tab-alternate-options-exp {
    border-radius: 5px;
    border: 1px solid var(--background-colorThree);
    display: flex;
    justify-content: space-between;
    padding: .8rem;
    cursor: pointer;
    transition: all .1s ease;
}

.tab-alternate-options-exp:hover{
    background-color: var(--secondry);
}

.tab-alternate-options-exp>p{
    color: var(--text_color_three);
}

@media only screen and (max-width: 610px) {

    /* .calendar-container-head>p{
        padding-left: 2.3rem;
    } */

    .calendar-container {
        border-radius: 20px 20px 0px 0px;
        width: 100%;
        position: absolute;
        bottom: 0;
    }

    .edit-tabs-container-mob-height-calendar {
        align-items: flex-end;
    }

    /* .calendar-btn-container {
        padding: 1rem 3.9rem;
    } */

    .calendar-container-head>img {
        right: 3.5rem;
    }
}