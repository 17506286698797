.css-4jnixx-MuiStack-root>.MuiTextField-root {
    width: 100% !important;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
    height: 52px;
}

.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
    color: var(--text_color_two) !important;
    font-size: .85rem !important;
    top: 8px !important;
    background-color: red;
    /* line-height: 0 !important; */
}

.css-4jnixx-MuiStack-root {
    padding-top: 0 !important;
    overflow: hidden !important;
}