.widget-container {
    /* border: 1px solid red; */
    /* height: 10rem; */
    display: flex;
    justify-content: flex-end;
    padding: 1rem;
    align-items: center;
    position: fixed;
    bottom: 0;
    right: 0;
    /* left: 0; */
    z-index: 8;
}

.widget-circle {
    background-color: var(--primary);
    height: 3.5rem;
    width: 3.5rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    /* box-shadow: 0px 6px 7px 0px #5086FF4D; */
    z-index: 1;

}

.widget-num-container {
    padding: 1rem 1.5rem;
    background-color: var(--background-colorThree);
    border-radius: 28px;
    width: 13rem;
    text-align: center;
    font-weight: 500;
    height: fit-content;
    position: absolute;
    right: 0;
}

.widget-num-container>a {
    color: var(--text_color_three);
}

.widget-parent {
    display: flex;
    align-items: center;
    position: relative;
    /* border: 1px solid blue; */
}

.slide-left {
    -webkit-animation: slide-left 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-left 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


@-webkit-keyframes slide-left {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    100% {
        -webkit-transform: translateX(-30px);
        transform: translateX(-30px);
    }
}

@keyframes slide-left {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    100% {
        -webkit-transform: translateX(-30px);
        transform: translateX(-30px);
    }
}



.rotate-90-cw {
    -webkit-animation: rotate-90-cw 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: rotate-90-cw 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


@-webkit-keyframes rotate-90-cw {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotate-90-cw {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}



/* responseiveness --------------- */

@media only screen and (max-width: 610px) {
    .widget-container {
        display: none;
    }
}